import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import useLoginStore from '@/store/modules/login'
import { storeToRefs } from 'pinia'
import { localCache } from '@/utils/cache'
import { filterAsyncRoutes } from '@/utils/math'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import('@/components/layout/layout.vue'),
    name: 'main',
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/dashboard.vue'),
      },
      // {
      //   path: '/user/user',
      //   component: () => import('@/views/user/user.vue'),
      // },
      // {
      //   path: '/sale-manage/group',
      //   component: () => import('@/views/sale-manage/group.vue'),
      // },
      // {
      //   path: '/sale-manage/packet',
      //   component: () => import('@/views/sale-manage/packet.vue'),
      // },
      // {
      //   path: '/case-store/category',
      //   component: () => import('@/views/case-store/category.vue'),
      // },
      // {
      //   path: '/case-store/doctor',
      //   component: () => import('@/views/case-store/doctor.vue'),
      // },
      // {
      //   path: '/case-store/case',
      //   component: () => import('@/views/case-store/case.vue'),
      // },
      // {
      //   path: '/case-store/operate',
      //   component: () => import('@/views/case-store/operate.vue'),
      // },
      // {
      //   path: '/system/permission',
      //   component: () => import('@/views/system/permission.vue'),
      // },
      // {
      //   path: '/system/role',
      //   component: () => import('@/views/system/role.vue'),
      // },
      // {
      //   path: '/system/admin',
      //   component: () => import('@/views/system/admin.vue'),
      // },
      // {
      //   path: '/system/log',
      //   component: () => import('@/views/system/log.vue'),
      // },
      // {
      //   path: '/system/action',
      //   component: () => import('@/views/system/action.vue'),
      // },
    ]
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401.vue'),
  },
  {
    path: '/login',
    component: () => import('@/views/login/login.vue'),
  },
  // {
  //   path: '/:pathMatch(.*)',
  //   redirect: '/404',
  // },
  {
    path: '/:pathMatch(/:W+)',
    redirect: '/404',
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

const whiteList = ['/login', '/401', '/404']
let loginStore: any

router.beforeEach(async (to, before, next) => {
  NProgress.start()
  loginStore = useLoginStore()
  const { permissiones } = storeToRefs(loginStore)
  if (whiteList.includes(to.path)) {
    next()
    NProgress.done()
    return
  } else {
    localCache.setCache('currentFullPath', to.fullPath)
    if (!whiteList.includes(to.path) && permissiones.value.length === 0) {
      await loginStore.getUserPermissionAction()
      let menuRoutes = await filterAsyncRoutes(loginStore.userPermissionList)
      for (let item of menuRoutes) {
      router.addRoute('main', item)
      }
      if (permissiones.value.includes(to.path)) {
        next({ ...to, replace:true })
      } else {
        next({
          path: '/dashboard',
          replace: true
        })
      }
      NProgress.done()
     }
  }
  next()
  NProgress.done()
})

router.afterEach(() => {
  NProgress.done()
})
export default router

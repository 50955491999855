import request from '../request'
import qs from 'query-string'

export function getUserListApi(params: any) {
  let query = qs.stringify(params)
  return request.get(`/admin/user?${query}`)
}

export function getParentInfoApi(id: number) {
  return request.get(`/admin/user/parent?user_id=${id}`)
}

// 渠道列表
export function getSourceGroupApi() {
 let query = qs.stringify({ page: 1, pageSize: 100 })
 return request.get(`/admin/source_group?${query}`)
}

// 设为分销
interface addStorePersonType {
  id: number,
  name: string,
  group1_id: number | string,
  group2_id?: number | string,
}
export function addStorePersonApi(parms: addStorePersonType) {
  return request.post('/admin/user/setDistributor', parms)
}

//编辑 userid
export function editUserIdApi(params: { id: number | string, user_id: number | string }) {
  return request.post("/admin/source_distributor", params);
}
// 取消分销
export function cancelDistributorApi(params: { id: number | string }) {
  return request.post('/admin/user/cancelDistributor', params)
}
import request from '../request'
export function uploadFile(option: any) {
  const formData = new FormData()
  formData.append("file", option.file)
  return request({
    url: "/uploadFile",
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress: option.uploadProgress ? option.uploadProgress : () => {}
  })
}
import request from '../request'
import qs from 'query-string'

// 分类列表
export function getCaseCategoryListApi(id: number | string) {
  let query = qs.stringify({
    page: 1,
    pageSize: 100,
    dept_id: id
  })
  return request.get(`/admin/case_category?${query}`)
}

// 添加科室分类
export function addSecondCaseCategoryApi(params: any) {
  return request.post('/admin/case_category', params)
}

// 删除分类
export function deleteSecondCaseCategoryApi(id: number | string) {
  return request.delete(`/admin/case_category/${id}`)
}

// 科室列表
export function getDoctorDepListApi() {
  let query = qs.stringify({
    page: 1,
    pageSize: 100
  })
  return request.get(`/admin/doctor_dep?${query}`)
}
// 添加科室

export function addSecondDoctorDepApi(params: any) {
  return request.post('/admin/doctor_dep', params)
}
// 医生列表
export function getDoctorListApi(params: any) {
  let query = qs.stringify(params)
  return request.get(`/admin/doctor?${query}`)
}

// 创建修改医生
export function addDoctorApi(params: any) {
  return request.post('/admin/doctor', params)
}

// 删除医生
export function deleteDoctorApi(id: number | string) {
  return request.delete(`/admin/doctor/${id}`)
}

// 案例列表
export function getCaseListApi(params: any) {
  let query = qs.stringify(params)
  return request.get(`/admin/case_list?${query}`)
}

// 添加修改案例
export function addCaseApi(params: any) {
  return request.post('/admin/case_list', params)
}

// 删除案例
export function deleteCaseApi(id: number) {
  return request.delete(`/admin/case_list/${id}`)
}

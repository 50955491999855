import request from '../request'
import qs from 'query-string'

// 获取所有权限列表
export function getAllPermissionListApi() {
  return request.get('/admin/permission')
}

// 新增/修改权限
export function setPermissionStatusApi(params: any) {
  return request.post(`/admin/permission`, params)
}

// 删除权限
export function deletePermissionApi(id: number) {
  return request.delete(`/admin/permission/${id}`)
}

//获取账号
export function getAllAdminListApi(params: any) {
  const query = qs.stringify(params)
  return request.get(`/admin/admin?${query}`)
}

// 更改账号状态, 新增。 编辑
export function setRoleStatusApi(params: any) {
  return request.post(`/admin/admin`, params)
}

// 删除账号
export function deleteAccoutApi(id: number) {
  return request.delete(`/admin/admin/${id}`)
}


// 角色列表
export function getAllRoleListApi(page: number = 1, pageSize: number = 100000) {
  const query = qs.stringify({
    page: page,
    pageSize: pageSize
  })
  return request.get(`/admin/role?${query}`)
}

// 角色新增/编辑/禁用/启用
export function setStatusApi(params: any) {
  return request.post('/admin/role', params)
}

// 删除角色
export function deleteRoleApi(id: number) {
  return request.delete(`/admin/role/${id}`)
}

// 日志列表
export function getLogListApi(params: any) {
  let query = qs.stringify(params)
  return request.get(`/admin/log/login?${query}`)
} 

// 操作日志
export function getLogActionListApi(params: any) {
  let query = qs.stringify(params)
  return request.get(`/admin/log/action?${query}`)
}
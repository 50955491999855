import Layout from '@/components/layout/layout.vue'
export function padLeftZero(num: number): string {
let str = `00${num}`
return str.substring(`${num}`.length)
}

export function getMenuList(menus: any[]): string[] {
  let list: string[] = []
  getMenuListFn(menus)
  function getMenuListFn(menuList: any[]) {
    for (let item of menuList) {
      if (item.type === 2) {
        list.push(item.e_name)
      }
      if (item.children && item.children) {
        getMenuListFn(item.children)
      }
    }
  }
  return list
}

export function getBreadcrumbList(url: string, menuList: any[]) {
  let list: any[] = []
  let flag = false
  getBreadcrumbListFn(menuList)
  function getBreadcrumbListFn(array: any[]) {
    for(let item of array) {
      flag = false
      if (item.e_name === url) {
        flag = true
        list.push(item)
        break
      } else {
        if (item.children && item.children.length > 0) {
          getBreadcrumbListFn(item.children)
          if (flag) {
            list.unshift(item)
            break
          }
        }
      }
    }
  }
  return list
}

// export function filterAsyncRoutes(asyncRouterMap_i: any[]) {
//   let asyncRouterMap = JSON.parse(JSON.stringify(asyncRouterMap_i))
//   for (var i = asyncRouterMap.length - 1; i >= 0; i--) {
//     const item = asyncRouterMap[i]
//     if (item.component == 'Layout') {
//       asyncRouterMap[i].component = Layout
//       asyncRouterMap[i]['path'] = item.e_name
//       asyncRouterMap[i]['meta'] = {
//         title:item.title,
//         icon: item.icon || ''
//       }
//     } else {
//       if (item.type == 1) {
//         asyncRouterMap[i]['path'] = item.e_name
//         asyncRouterMap[i]['meta'] = {
//           title:item.title,
//           icon: item.icon || ''
//         }
//         asyncRouterMap[i].component = _import(item.component)
//       } else if (item.type == 2) {
//         asyncRouterMap[i]['path'] = item.e_name
//         asyncRouterMap[i]['meta'] = {
//           title:item.title,
//           icon: item.icon || ''
//         }
//         const path = item.e_name
//         asyncRouterMap[i].component = _import(path) 
//       }
//     }
//   }
//   return asyncRouterMap
// } 

export function filterAsyncRoutes(asyncRouterMap_i: any[]) {
  let listMap: any[] = []
  menuToRouteFn(asyncRouterMap_i)
  function menuToRouteFn(list: any[]) {
    for (let item of list) {
      if (item.type == 2) {
        let obj = {
          path: item.e_name,
          component: _import(item.e_name),
          meta: {
            title: item.title,
            icon: item.icon ?? ''
          }
        }
        listMap.push(obj)
      } else {
        if (item.children && item.children.length > 0) {
          menuToRouteFn(item.children)
        }
      }
    }
  }
  return listMap
} 

function _import(file: string) {
  try {
    return require('@/views' + file + '.vue').default
  } catch {
    return  require('@/views' + '/error-page/404' +'.vue').default
  }
}

export function getoffset(eleclassName: string) {
  var offset = {
      left:0,
      top:0
  }
  let ele: any = document.querySelector(eleclassName)
  while(ele!==document.body){
    offset.left += ele.offsetLeft
    offset.top += ele.offsetTop
    ele = ele.offsetParent
  }
  return offset
}

export function getPageSize() {
  return {
    width: document.documentElement.clientWidth || document.body.clientWidth,
    height: document.documentElement.clientHeight || document.body.clientHeight
  }
}